/* You can add global styles to this file, and also import other style files */

//@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
//@import '~@angular/cdk/overlay-prebuilt.css';
@import url(http://fonts.googleapis.com/css?family=Open+Sans);
@import '~quill/dist/quill.snow.css';

body {
/* 	background: #b8b1e8; */
	color: #000;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	font-family: 'Lato', Arial, sans-serif;
	margin:0;
	padding:0;
	padding-bottom:40px;
}

/* Sticky Navigation */
.ccsticky-nav {
  width: 100%;
  height: 60px; 
  position: sticky; 
  top: 0;
  background: tan;
  z-index:999;
}

.ccsticky-nav ul {
  padding: 20px;
  margin: 0 auto;
  list-style: none;
  text-align: center;
}
.ccsticky-nav ul li {
  display: inline-block;
  margin: 0 10px;
}
.ccsticky-nav ul li a {
  padding: 10px 0;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.2s ease;
  text-transform: capitalize;
}
.ccsticky-nav ul li a:hover {
  color: #b64fd3;
}


/* Headings */

h1 {
    color: #34495e;
    font-size: 2em;
    font-weight: 600;
}



section p {
    font-size: 23px;
    font-weight: 300;
    line-height: 42px;
}


/****  Content  ****/

.content {
  width: 80%;
  max-width: 1200px;
  background: #ffffff;
  margin: 50px auto;
  min-height: 700px;
/*  padding: 100px; */
  color: #444;
}

.source-form {
/*     min-width: 150px; */
/*     max-width: 900px; */
/*     width: 100%; */
/*     margin: 5px;  */
}

.source-field {
    width: 100%;
}

.section-title-small {
	font: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 11px;
	color: slategray;
	padding-bottom: 5px;
}

.section-title {
	font: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	color: slategray;
	padding-bottom: 5px;
}

.mat-mdc-form-field {
	font-size: 14px;
/*     display: block; */
}

textarea {
    height: 100px;
    resize: vertical;
}

app-taglookup, app-organization-lookup, app-author-lookup {
	width: 100%;
}

.list-pager {
	width: 100%
}

.mat-mdc-standard-chip {
	min-height: 28px !important;
}

.mat-mdc-chip {
/*	background-color: red !important;*/
	font-size: 12px;
}

/* https://sharkcoder.com/visual/underline */
markdown p a {
	text-decoration: none;
	color: inherit;
	background-image: linear-gradient(to right, rgb(46, 52, 54) 0%, rgba(46, 52, 54) 100%);
	background-repeat: repeat-x; 
	background-position: 0 100%; 
	background-size: 100% 4px; 
}

/* https://max.hn/thoughts/how-to-create-a-highlighter-marker-effect-in-css */
markdown p strong {
	margin: 0 -0.4em;
	padding: 0.1em 0.4em;
	border-radius: 0.8em 0.3em;
	background: transparent;
	background-image: linear-gradient(
		to right,
		rgba(255, 225, 0, 0.1),
		rgba(255, 225, 0, 0.7) 4%,
		rgba(255, 225, 0, 0.3)
	);
	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

h3 {
	margin: 5px 0 5px 0;
	font-weight: 600;
	font-family: 'Titillium Web', sans-serif;
	position: relative;
/* 	text-shadow: 0 -1px 1px rgba(0,0,0,0.4); */
	font-size: 16px;
	line-height: 40px;
	color: #355681;
	text-transform: uppercase;
/* 	border-bottom: 1px solid rgba(53,86,129, 0.3); */
}

h3:after {
/*	content: ' \27B2'; */
/* 	font-size: 20px; */
}

.toolbar-menu {
	background-color: tan !important;
	color: white !important;
}

.toolbar-menu span,
.toolbar-menu .mat-icon {
    color: white !important;
}

.data-table .mat-mdc-cell, .mat-mdc-header-cell {
    padding: 8px 8px !important;
}

.data-table .mat-mdc-header-row {
	height: 40px !important;
}

.data-table .mat-mdc-header-cell {
	font-size: 14px;
	color: white;
}

.data-table .mat-mdc-header-row {
	background-color: #8CAAD1;
}

.data-table .mat-mdc-row:hover {
	background: whitesmoke !important;
}

.data-table .mat-mdc-row {
	cursor: pointer;
}

.page-header {
	padding-bottom: 10px;
	color: #355681;
}

.search-box {
	position: relative;
}

.search-container {
	display: flex;
	align-items: center;
}

.search-box .search-container button {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
}

.search-input {
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
	opacity: 0;
	transition: width 0.3s, opacity 0.3s;
}

@media (min-width: 768px) {
  .search-input {
    width: 500px !important; /* Adjust width for screens larger than 768px */
  }
}

.search-input.visible {
	width: auto;
	opacity: 1;
}
/*
mat-form-field .mat-mdc-form-field-infix {

}

mat-form-field .mat-mdc-text-field-wrapper.mat-mdc-text-field-wrapper {

}
*/
